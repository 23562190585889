/*100 300 reg 500 600 bold */
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');*/


/*** CUSTOM FONTS - POPPINS ***/
/* poppins-100 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-100.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-100.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-100.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-100.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-100.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-100.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-100italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-100italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-100italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-100italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-100italic.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-100italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-100italic.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-200 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-200.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-200.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-200.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-200.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-200.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-200.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-200italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-200italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-200italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-200italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-200italic.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-200italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-200italic.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-300.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-300.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-300italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-300italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-300italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-300italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-300italic.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-300italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-300italic.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-regular.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-italic.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-italic.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-500.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-500italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-500italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-500italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-500italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-500italic.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-500italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-500italic.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-600.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-600italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-600italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-600italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-600italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-600italic.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-600italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-600italic.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-700.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-700italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-700italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-700italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-700italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-700italic.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-700italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-700italic.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-800 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-800.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-800.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-800.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-800italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-800italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-800italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-800italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-800italic.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-800italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-800italic.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-900 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-900.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-900.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-900.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-900italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('./assets/fonts/Poppins/poppins-v20-latin-900italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/Poppins/poppins-v20-latin-900italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Poppins/poppins-v20-latin-900italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-900italic.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/Poppins/poppins-v20-latin-900italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Poppins/poppins-v20-latin-900italic.svg#Poppins') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/helvetica-neue/HelveticaNeueLight.ttf") format('truetype');
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/helvetica-neue/HelveticaNeueLightItalic.ttf") format('truetype');
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/helvetica-neue/HelveticaNeueMedium.ttf") format('truetype');
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/helvetica-neue/HelveticaNeueItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/helvetica-neue/HelveticaNeueBold.ttf") format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: italic;
  font-weight: 600;
  src: url("./assets/fonts/helvetica-neue/HelveticaNeueBoldItalic.ttf") format('truetype');
}


/*** CUSTOM FONTS - END ***/


body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* table headings font weight bold */
.MuiTableCell-head {
  background: #f5f5f5;
}

/* indent table cells for grouping elements @firmaeditjur */
.indenTableCell {
  padding-left: 30px !important;
}

.modalCenterButton {}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6c757d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #495057;
}

/**** Table Styles ****/
tbody td,
tbody th {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}

.modifiedValue {
  color: #F50000 !important;
}